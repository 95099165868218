body {
    background: radial-gradient(#e5e5e5, #fff, #e5e5e5);
}

/* li {
    padding: 10px !important;
} */

.avatar img {
    border: solid 6px transparent;
    border-radius: 55%;
}

.bio-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.img-col {
    border-right: 1px solid lightgray;
    margin: auto 0;
}

.card-row {
    /* border: 1px solid black!important; */
    margin-left: 0px !important;
    margin-right: 0px !important;
    /* box-shadow: rgba(235, 235, 243, 0.2) 0px 7px 29px 0px !important; */
    position: relative;
    display: flex;
    /* flex-direction: column; */
    height: 200px !important;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1 px solid rgba(0, 0, 0, .125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
}

.card-row:hover {
    transform: scale(1.05);
}

.top-educators {
    padding-top: 70px;
    /* padding-bottom: 70px; */
}

.top-educators-private {
    padding-top: 20px;
    /* padding-bottom: 70px; */
}

.card {
    width: 27rem;
    height: 20rem;
    border-radius: 6px !important;
}

.card-img-width {
    max-width: 75% !important;
    padding: 0px;
    height: fit-content;
    padding: 20px 10px 10px 10px;
    border-radius: 23px !important;
}

.top-educators-right {
    text-align: right;
    /* padding-top: 31px; */
    color: green;
}

.top-educators-right a {
    cursor: pointer;
}

.card:hover {
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

.card-body {
    padding: 3rem 0 !important;
}

.testcenter {
    text-align: center;
}

.card-text {
    font-size: 0.5rem !important;
    padding: 0.4rem 1.9rem;
}

.container-fluid.row {
    padding-top: 6rem;
}

.overflow {
    overflow: hidden;
}

.card-img-top {
    transform: scale(1);
    width: 75% !important;
    height: 75% !important;
    transition: transform 0.5s ease;
}

.card-img-top:hover {
    transform: scale (1.8);
}

.imgheiwi {
    height: 156px;
    width: 119px;
}


.title-bar {
    margin-left: 0% !important;
}

.text-style:hover {
    /* color:white; */
    text-decoration: none !important;
    cursor: pointer !important;
}

.img-instru-mob {
    height: 10rem;
    width: 100%;
    padding: 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
}

@media (max-width: 568px) {
    .font-mob {
        font-size: 18px;
        text-align: center;
        padding-bottom: 0px
    }

    .top-educators {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .para-mob {
        text-align: center;
        margin-bottom: 0px !important;
    }

    .card-seeall {
        padding: 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px;
        font-size: 12px;
    }

    .top-educators-right {
        padding-top: 0px !important;
    }

    .img-instru-mob {
        height: 15rem;
        width: 100%;
        padding: 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px;
    }

    .testal-mob {
        text-align: center;
    }

    .mob-v-profile {
        padding: 0px 0px;
        margin: 0px 0px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 2) {
    .img-instru-mob {
        height: 7rem;
        width: 100%;
    }

    /* .instructor-img {
    padding-top: 30px !important; */
    /* } */
}