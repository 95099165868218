/* Add this CSS to your BlogDetails.css or any relevant CSS file */
.blog-content img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    /* Ensures the image is responsive */
}

.divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 20px 0;
}

.twitter-embed-container {
    display: flex;
    justify-content: center;
    /* Center the embedded tweet */
    transform: scale(0.7);
    /* Scale down to 80% */
    transform-origin: top center;
    /* Center scaling from the top */
    margin: 20px 0;
    /* Add some vertical space if needed */
}

.twitter-embed-container blockquote.twitter-tweet {
    margin: 0 auto;
    /* Center the tweet itself if needed */
}

.date-and-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.dates {
    flex: 1;
}

.download-button {
    margin-left: 20px;
    padding: 10px 20px;
    background-color: #3aada8;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
}

.download-button:hover {
    background-color: #0056b3;
}

.blog-content {
    margin-top: 20px;
}

#pdfContainer {
    font-size: 18px;
    /* Increase font size for content */
    color: #000;
    /* Ensure the text color is black */
}

.download-button {
    position: absolute;
    top: 20px;
    right: 20px;
}

.date-and-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dates {
    flex-grow: 1;
}

h3 {
    font-size: 24px;
}

/* Normal image state */
.blog-content img {
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
    /* Smooth transition with transform */
    max-width: 100%;
    /* Ensure the image does not stretch outside container */
    height: auto;
}

/* Enlarged image state */
.blog-content img.enlarged {
    transform: scale(2);
    /* Enlarges the image by 2x */
    cursor: zoom-out;
    z-index: 1000;
    position: relative;
    /* Ensures the image is brought to the top when enlarged */
}