.cat-name {
  /* display: block;
  width: 104px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  /* display:block; */
  display: table-cell !important;
  vertical-align: middle !important;
  width: 100%;
  height: 70px;
  overflow: hidden !important;
  text-align: center !important;
}

.card-img-overlay {
  background-color: #FFF;
  ;
}

.justify-content-centeraa {
  background: unset;

}

.text-centeraa {
  text-align: center !important;
  background: unset;
}

.cardUi {
  position: relative;
  display: unset;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  width: 180px;

}

.carduiimg {
  width: 185px;
  border-radius: 10px;
}

.inlinedisplay {
  display: inline-flex;
}

.padding-0 {
  padding-right: 0px;
  padding-left: 0px;
}

.trans:hover {
  width: 100px;
  height: 100px;
  background: red;
  transition: width 3s;
  transition-delay: 1s;

}

.aligh-mob {
  text-align: center;
}

hr {
  width: 63%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 1rem; */
  text-align: center;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(77, 72, 72, 0.1) !important;
}



@media screen and (max-width: 1225px) and (min-width: 1045px) {
  .priority-5 {
    display: none;
  }
}

@media screen and (max-width: 1045px) and (min-width: 835px) {
  .priority-5 {
    display: none;
  }

  .priority-4 {
    display: none;
  }
}

@media screen and (max-width: 565px) and (min-width: 300px) {
  hr {
    width: 63%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 1rem; */
    text-align: center;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(77, 72, 72, 0.1) !important;
  }

  .priority-5 {
    display: none;
  }

  .priority-4 {
    display: none;
  }

  .priority-3 {
    display: none;
  }

  p {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 22px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 300px) {
  .priority-5 {
    display: none;
  }

  .priority-4 {
    display: none;
  }

  .priority-3 {
    display: none;
  }

  .priority-2 {
    display: none;
  }
}

@media all and (min-width: 480px) {
  .deskContentfree {
    display: block;
  }

  .phoneContentfree {
    display: none;
  }

  img {
    max-width: 100% !important;
  }

}

@media all and (max-width: 479px) {
  .deskContentfree {
    display: none;
  }

  .phoneContentfree {
    display: block;
  }

}

.borderless td,
.borderless th {
  border: none;
}


.press li {
  display: inline-block;
  /* Get all images to show in a row */
  width: 25%;
  /* Show 4 card per row */
  text-align: center;
  /* Centre align the images */
  margin-top: 21px;
  ;
  /* margin betwenn li */
}

@media (max-width: 960px) and (min-width: 501px) {
  .press li {
    width: 50%;
  }

  /* Show 2 logos per row on medium devices (tablets, phones in landscape) */
}

@media (max-width: 500px) {
  /* .press li { width: 100%; }  */
  /* On small screens, show one logo per row */

  .press li {
    width: 50%;
  }

  /* Show 2 logos per row on medium devices (tablets, phones in landscape) */

}

@media (max-width: 568px) {
  .font-mob {
    font-size: 18px;
    text-align: center;
    padding-bottom: 0px;
    padding-top: 20px !important;
  }

  .aligh-mob {
    text-align: justify !important;
  }

  .sellall {
    padding: 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
  }

  /* .text{
      font-size: 25px !important;
} */
  .card-mob {
    width: 100% !important;
  }


}

.sellall {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* Float four columns side by side */
.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}