@media (min-width:320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */


  /* .button{
    width: 50% !important;
    border-radius: 5px;
  } */
  /* .btn-primary{
    margin: auto !important;
  } */
  .container-fluid {
    margin-top: 0px;
    padding-top: 0px;
  }
}

@media (min-width:480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width:600px) {


  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width:801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
  /* big landscape tablets, laptops, and desktops */
  /* .screen-padding {
      padding-left: 90px ;
      padding-right: 90px ;

  } */

  .form-block {
    margin-left: 400px;
    margin-right: 400px;
  }
}

@media (min-width:1281px) {
  /* hi-res laptops and desktops */
  /* .screen-padding {
      padding-left: 90px ;
      padding-right: 90px ;
  } */
}

.sign-in-width {
  width: 50% !important;
}

.letter-remaining {
  font-size: 12px !important;
  padding-left: 5px !important;
}

.forgot-psw {
  cursor: pointer;
}

.profile-details {
  border: 1px solid;
  border-radius: 5px;
  color: #5a636b;
}

.profile-h4 {
  padding-bottom: 0px;
  padding-top: 20px;
}

.label {
  background: blueviolet !important;
  color: black !important;
}

body {
  margin: 0;
  background-color: white !important;
}

.gender-label {
  padding-left: 9px;
}

/* Scroll to Top */
#scroll-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.inputotp {
  width: 175px !important;
}

.modal-footer {
  text-align: center !important;
}

.regi {
  margin: auto;
  width: 350px;
  margin-top: 140px;
}

.nounderline {
  text-decoration: none;
}

a:hover,
a:active,
a:visited,
a:link {
  text-decoration: none;
}

.popup {
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  outline: 0;
  position: absolute;
  top: 250px;
}

/* .signup-main {
  padding: 60px 0px !important;
} */

@media only screen and (max-width: 600px) {
  .regi {
    margin: auto;
    width: 360 px;

  }

  .MuiGrid-item {
    text-align: center !important;
  }

  .regi {
    margin: auto;
    width: 100%;
  }

  .nav-menu.active {
    height: auto !important;
  }
}

.cardUser {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

.userVerifyButtons {
  justify-content: center !important;
}

@media (max-width: 568px) {
  .signin {
    text-align: center;
  }
}

.signin {
  text-align: center;
}

.btn-sign-in {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0px 25% !important;
  padding: 10px 0px;
}

.width-50 {
  width: 40% !important;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1283px !important;
  }
}

/* lrtserterrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr */

header {
  background: #fff;
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 0 30px rgb(0 0 0 / 10%);
  transition: 0.2s all;
  z-index: 999;
}