.active-cyan input[type=text] {
  border-bottom: 1px solid #4dd0e1;
  box-shadow: 0 1px 0 0 #4dd0e1;
}

.notifications {
  margin-top: 30px !important;
}

.m6hidden {
  display: none !important;
}

.qbody {
  height: fit-content !important;
}

.nav-link.active {
  color: lightblue;
  /* Add any other styles you want to apply when the tab is active */
}

.nav-tabs {
  border-bottom: none !important;
}

.search-exam {
  /* margin-top: 20px; */
  margin: 0px !important;
}

.list-header {
  margin-top: 20px;
}

.exam-tab {
  /* margin-top: 20px; */
  border: 1px solid rgb(124, 124, 168);
}

.exam-thumb img {
  text-align: center;
  justify-content: center;
  height: 20vh;
}

/* .text-name-exam h4{
    margin-bottom: -24px!important;
    font-weight: 500;
} */
.progress-bar {
  width: 25%;
}

.exam-bar {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
}

.exam-title {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* h4{
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 0.2;
} */
.first {
  line-height: 1.2 !important;
}

.first1 {
  line-height: 1.2 !important;
}

.col-form-label {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.form-control {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.padding-bottom {
  margin-bottom: 20px !important;
  margin-right: 5px !important;
}

.tab-content {
  border-top: 1px solid rgb(121, 121, 145) !important;
  padding-top: 10px !important;
  margin-bottom: 50px !important;
}

.justify-content-center {
  margin: 0px !important;
}

.no-exams {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 50px;
}

/* .m5show {
    display: flex !important;
}  
.m5hidden {
    display: none !important;
} */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .screen-padding-exs {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .screen-padding-exs {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .screen-padding-exs {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .screen-padding-exs {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .nav-tabs {
    height: 50px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .screen-padding-exs {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .nav-tabs {
    height: 50px !important;
  }
}




/* ########### Preview ########## */


.card {
  width: auto;
  height: auto;
}






/* new************* */



.portlet {
  margin-bottom: 15px;
}

.btn-white {
  border-color: #cccccc;
  color: #333333;
  background-color: #ffffff;
}

.portlet {
  border: 1px solid;
}

.portlet .portlet-heading {
  padding: 0 15px;
}

.portlet .portlet-heading h4 {
  padding: 1px 0;
  font-size: 16px;
}

.portlet .portlet-heading a {
  color: #fff;
}

.portlet .portlet-heading a:hover,
.portlet .portlet-heading a:active,
.portlet .portlet-heading a:focus {
  outline: none;
}

.portlet .portlet-widgets .dropdown-menu a {
  color: #333;
}

.portlet .portlet-widgets ul.dropdown-menu {
  min-width: 0;
}

.portlet .portlet-heading .portlet-title {
  float: left;
}

.portlet .portlet-heading .portlet-title h4 {
  margin: 10px 0;
}

.portlet .portlet-heading .portlet-widgets {
  float: right;
  margin: 8px 0;
}

.portlet .portlet-heading .portlet-widgets .tabbed-portlets {
  display: inline;
}

.portlet .portlet-heading .portlet-widgets .divider {
  margin: 0 5px;
}

.portlet .portlet-body {
  padding: 15px;
  background: #fff;
}

.portlet-body {
  overflow-y: auto;
  width: auto;
  height: 300px;
}

.portlet .portlet-footer {
  padding: 10px 15px;
  background: #e0e7e8;
}

.portlet .portlet-footer ul {
  margin: 0;
}

.portlet-green,
.portlet-green>.portlet-heading {
  border-color: #16a085;
}

.portlet-green>.portlet-heading {
  color: #fff;
  background-color: #16a085;
}

.portlet-orange,
.portlet-orange>.portlet-heading {
  border-color: #f39c12;
}

.portlet-orange>.portlet-heading {
  color: #fff;
  background-color: #f39c12;
}

.portlet-blue,
.portlet-blue>.portlet-heading {
  border-color: #2980b9;
}

.portlet-blue>.portlet-heading {
  color: #fff;
  background-color: #2980b9;
}

.portlet-red,
.portlet-red>.portlet-heading {
  border-color: #e74c3c;
}

.portlet-red>.portlet-heading {
  color: #fff;
  background-color: #e74c3c;
}

.portlet-purple,
.portlet-purple>.portlet-heading {
  border-color: #8e44ad;
}

.portlet-purple>.portlet-heading {
  color: #fff;
  background-color: #8e44ad;
}

.portlet-default,
.portlet-dark-blue,
.portlet-default>.portlet-heading,
.portlet-dark-blue>.portlet-heading {
  border-color: #34495e;
}

.portlet-default>.portlet-heading,
.portlet-dark-blue>.portlet-heading {
  color: #fff;
  background-color: #34495e;
}

.portlet-basic,
.portlet-basic>.portlet-heading {
  border-color: #333;
}

.portlet-basic>.portlet-heading {
  border-bottom: 1px solid #333;
  color: #333;
  background-color: #fff;
}

@media(min-width:768px) {
  .portlet {
    margin-bottom: 30px;
  }
}

.img-chat {
  width: 40px;
  height: 40px;
}

.text-green {
  color: #16a085;
}

.text-orange {
  color: #f39c12;
}

.text-red {
  color: #e74c3c;
}

.modal-footer {
  display: contents !important;
  flex: none !important;
}

/* **********chat*********** */

* {
  box-sizing: border-box;
}

body {
  background-color: #edeff2;
  font-family: "Calibri", "Roboto", sans-serif;
}

.chat_window {
  position: absolute;
  width: calc(100% - 20px);
  max-width: 800px;
  height: 500px;
  border-radius: 10px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
  overflow: hidden;
}

.top_menu {
  background-color: #fff;
  width: 100%;
  padding: 20px 0 15px;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
}

.top_menu .buttons {
  margin: 3px 0 0 20px;
  position: absolute;
}

.top_menu .buttons .button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.top_menu .buttons .button.close {
  background-color: #f5886e;
}

.top_menu .buttons .button.minimize {
  background-color: #fdbf68;
}

.top_menu .buttons .button.maximize {
  background-color: #a3d063;
}

.top_menu .title {
  text-align: center;
  color: #bcbdc0;
  font-size: 20px;
}

.messages {
  position: relative;
  list-style: none;
  padding: 20px 10px 0 10px;
  margin: 0;
  height: 347px;
  overflow: scroll;
}

.messages .message {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s linear;
  opacity: 0;
}

.messages .message.left .avatar {
  background-color: #f5886e;
  float: left;
}

.messages .message.left .text_wrapper {
  background-color: #ffe6cb;
  margin-left: 20px;
}

.messages .message.left .text_wrapper::after,
.messages .message.left .text_wrapper::before {
  right: 100%;
  border-right-color: #ffe6cb;
}

.messages .message.left .text {
  color: #c48843;
}

.messages .message.right .avatar {
  background-color: #fdbf68;
  float: right;
}

.messages .message.right .text_wrapper {
  background-color: #c7eafc;
  margin-right: 20px;
  float: right;
}

.messages .message.right .text_wrapper::after,
.messages .message.right .text_wrapper::before {
  left: 100%;
  border-left-color: #c7eafc;
}

.messages .message.right .text {
  color: #45829b;
}

.messages .message.appeared {
  opacity: 1;
}

.messages .message .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
}

.messages .message .text_wrapper {
  display: inline-block;
  padding: 20px;
  border-radius: 6px;
  width: calc(100% - 85px);
  min-width: 100px;
  position: relative;
}

.messages .message .text_wrapper::after,
.messages .message .text_wrapper:before {
  top: 18px;
  /* border: solid transparent; */
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.messages .message .text_wrapper::after {
  border-width: 13px;
  margin-top: 0px;
}

.messages .message .text_wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}

.messages .message .text_wrapper .text {
  font-size: 18px;
  font-weight: 300;
}

.bottom_wrapper {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  position: absolute;
  bottom: 0;
}

.bottom_wrapper .message_input_wrapper {
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #bcbdc0;
  width: calc(100% - 160px);
  position: relative;
  padding: 0 20px;
}

.bottom_wrapper .message_input_wrapper .message_input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 40px);
  position: absolute;
  outline-width: 0;
  color: gray;
}

.bottom_wrapper .send_message {
  width: 140px;
  height: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #a3d063;
  border: 2px solid #a3d063;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
  float: none;
}

.bottom_wrapper .send_message:hover {
  color: #a3d063;
  background-color: #fff;
}

.bottom_wrapper .send_message .text {
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
  line-height: 48px;
}

.message_template {
  display: none;
}

@media (max-width: 568px) {
  .exam-img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
    height: 14rem !important;
  }

  .btn-con {
    width: 100%;
  }

  .modal-title {
    padding-left: 0rem !important;
  }

  .exam-card {
    height: 3rem !important;
  }

  .close {
    width: 10% !important;
    margin: 0px 0px;
  }

  .margin {
    width: 84% !important;
  }


}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .exam-thumb img {
    height: 10vh;
  }

  .btn-con {
    width: 100%;
    padding: 5px 4px !important;
  }
}

.close {
  text-align: right;
}

.margin {
  padding-left: 8rem;
}

.desk-view {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
}

.exam-card {
  margin: 0px 0px !important;
}

.border1 {
  border: 1px solid #181919 !important;
}

/* .close-btn1{
    width: 20% ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 38% !important;
} */
@media (max-width:991px) and (min-width:768px) {
  .btn-ipad {
    margin: 5px 5px;
    font-size: 10px !important;
    word-wrap: break-word;
    white-space: break-spaces !important;
  }
}

.modal-overlay {
  position: fixed;
  top: 50%;
  /* Position it vertically at the center of the screen */
  left: 50%;
  /* Position it horizontally at the center of the screen */
  transform: translate(-50%, -50%);
  /* Adjust to truly center the element */
  width: 14%;
  /* You can keep your desired width */
  height: 40%;
  /* Or adjust to your needs */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}