.hamburger {
  margin-top: 16px;
}

.hover_category {
  font-size: 13px;
  /* Adjust the font size as needed */
}

.userprofilemobile {
  margin-top: 5px;
  padding-right: 0px !important;

}

/* https: //bootstrapious.com/p/how-to-change-bootstrap-navbar-height */
.navbar-custom {
  height: 75px;
}

.topnav {
  overflow: visible;
  background-color: #FFFFFF;
  z-index: -1 !important;
  /* box-shadow: 0px 1px rgb(231, 229, 229);
  border-bottom: 2px solid rgb(231, 229, 229); */
}

.profile-icon {
  border-radius: 100%;
  width: 45px;
  height: 45px;
}

.dropdown-toggle {
  cursor: pointer !important;
}

/* Add this CSS in your stylesheets */
.dropdown-menu {
  position: absolute;
  top: 100%;
  transform: translateX(-60%);
  left: 0 !important;
  z-index: 950;
  display: none;
  float: left !important;
  min-width: 10rem;
  padding: 0.5rem 0 !important;
  margin: -2rem 0 0 !important;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1.25rem !important;
}



.dropdown-item {
  color: rgb(14, 13, 13) !important;
  text-align: left !important;
  cursor: pointer !important;
}

.drp-btn {
  color: rgb(24, 23, 23) !important;
  padding: 2px !important;
  padding-left: 10px !important;
  cursor: pointer !important;
}

.header-img {
  margin: 1px 10px;
  width: 198px !important;
}

.top-bar-domain {
  position: relative;
  width: fit-content !important;
  border-radius: 5px !important;
  /* font-weight: bold !important; */
  height: 35px !important;
  cursor: pointer;
}

.top-bar-domain:hover {
  border: 2px solid;
}

.topnav a {
  float: left;
  color: #FFFFFF;
  text-align: right;
  padding: 10px 1px;
  text-decoration: none;
  /* font-size: 20px; */
  position: relative;
  right: 4px;
  top: 2px;
  text-decoration: none !important;
}

/* .topnav a:hover {
  background-color: #ddd;
  color: black;
} */

.topnav a.active {
  background-color: #4CAF50;
  color: white;
}

.topnav-right {
  float: right;
  padding-top: 2px;
}

.align-right {
  display: flex;
  justify-content: flex-end;
  /* Aligns the content to the end (right in LTR languages) */
  width: 100%;
  /* Ensure the parent container takes up the full width */
}

.switch-btn {
  height: fit-content;
  margin-top: 12px;
  color: #FFFFFF;
  margin-right: 10px !important;
  text-decoration: none !important;
}

.logo:hover {
  background: none;
}

@media (max-width: 568px) {
  .custom-select {
    width: 60% !important;
    margin: 10px 26px !important;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  @media (min-width: 320px) {
    .topnav {
      margin: 1px !important;
      padding: 1px !important;
    }
  }

  .switch-btn {
    margin-top: 3px;
  }
}

@media (max-width: 300px) {
  .topnav-right {
    float: none;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    /* padding-top: 30px; */
    margin: 5px 5px;
    text-align: center;
  }


}



@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 2) {
  .home__hero-section {
    margin-top: 0px;
  }
}

@media only screen and (min-device-width: 250px) and (max-device-width: 374px) and (-webkit-min-device-pixel-ratio: 2) {
  .header-img {
    margin-left: 20px;
  }

  .dropdown-menu {
    text-align: left !important;
    left: 0px !important;

  }

}

@media only screen and (min-device-width: 375px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .header-img {
    /* margin-right: 115px; */
    display: block;
  }

  .custom-select {
    max-width: 55%;
    margin: 0px 25px !important;

  }

  .topnav a {
    float: left;
    color: #FFFFFF;
    text-align: center;
    padding: 10px 1px;
    text-decoration: none;
    /* font-size: 20px; */
    position: relative;
    right: 4px;
    top: 2px;
    text-decoration: none !important;
  }
}

.offcanvas-header {
  display: none;
}

@media (max-width: 992px) {
  .offcanvas-header {
    display: block;
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 50%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: rgb(248, 245, 245);
    /* transition: visibility .2s ease-in-out, -webkit-transform .2s ease-in-out; */
  }

  .navbar-collapse.show {
    visibility: visible;
    transform: translateX(-100%);
  }

  .profilesidebar-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 45%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: rgb(248, 245, 245);
    /* transition: visibility .2s ease-in-out, -webkit-transform .2s ease-in-out; */
  }

  .profilesidebar-collapse.show {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 3;
}


.navbar .collapse {
  visibility: hidden;
  display: block;
}

.navbar .collapse.show {
  visibility: visible;
}

/* for small screens */
@media (max-width: 767px) {
  .navbar-nav .nav-link.active {
    visibility: visible;
  }

  .collapsing {
    transition: all 0.35s ease;
    height: 40px !important;
  }

  .collapse:not(.show) .navbar-nav .nav-item:not(.active) {
    display: none;
  }
}

/* for larger screens */
@media (min-width: 768px) {
  .navbar .collapse .navbar-nav .nav-item {
    visibility: visible;
  }
}