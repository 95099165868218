.blocks {
    padding: 150px 0px !important;
}

p,
span,
a,
ul,
li,
button {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    background: none;
}

strong {
    font-weight: 400;
}

.tile {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    background: #fff;
    padding: 20px;
    margin-bottom: 30px;
}

.purple,
.blue,
.red,
.orange,
.green {
    color: #fff;
}

.alert {
    margin-bottom: 2rem !important;
}

.button-row {
    margin-bottom: 20px;
    margin-top: 20px;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .screen-padding-exs {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .screen-padding-exs {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .screen-padding-exs {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .screen-padding-exs {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .screen-padding-exs {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }
}


@media (max-width: 568px) {
    .mob-blocks {
        padding: 34px 0px !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 2) {
    .ipad-red {
        height: 14rem;
    }

    .ipad-orange {
        height: 14rem;

    }

    .ipad-p {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: rgb(9, 9, 9);
    }
}

.ipad-p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(9, 9, 9);
}

.tile h3 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
}

@media (max-width: 568px) {
    .sidebar {
        display: inline;
    }
}