p {
    margin-top: 2;
    margin-bottom: 0rem;
}

.repo-creation-date {
    margin: 0;
    margin-left: 0px;
    margin-right: auto;
}

.repo-languages {
    margin-left: auto;
    margin-right: 0px;
}

.repo-duration {
    margin-left: auto;
    margin-right: auto;
}

.repo-durations {
    margin-left: 8px;
    margin-right: auto;
}

.avatar img {
    border: solid 6px transparent;
    border-radius: 50%;
}

.profile-box {
    border: 1px solid lightgray;
    padding: 10px;
    box-shadow: 5px 10px;
}

.card-list-ui {
    border: 1px solid !important;
    padding: 0px !important;
    box-shadow: 1px 3px #888888 !important;
}

.profile-head {
    transform: translateY(5rem)
}

.ul-ui {
    list-style-type: none;
}

.cover {
    /* background-image: url(https://images.unsplash.com/photo-1530305408560-82d13781b33a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80); */
    background: #fff;
    background-size: cover;
    background-repeat: no-repeat
}

body {
    /* background: #654ea3; */
    /* background: linear-gradient(to right, #e96443, #904e95); */
    min-height: 100vh;
    overflow-x: hidden
}

.image {
    height: 100%;
    width: 100%;
}

.NewCard {
    flex: auto !important;
}

.view-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects-main {
    width: 100%;
    text-align: center;
}

.basic-projects {
    margin-left: 5%;
    margin-right: 5%;
}

.projects-heading-div {
    display: flex;
}

.projects-heading-div>* {
    flex: 1;
}

.projects-heading-img-div {
    align-items: center;
    justify-content: center;
}

.projects-heading-text-div {
    text-align: center;
    margin-top: 25px;
}

.projects-heading-img-div>* {
    max-width: 100%;
    height: 420px;
}

.projects-heading-text {
    font-size: 36px;
    font-weight: 300;
    /* font-family: "Google Sans Medium"; */
    line-height: 1.1;
    text-align: center;
    margin-top: 40px;
}

.projects-heading-sub-text {
    font-size: 30px;
    font-family: "Google Sans Regular";
    text-align: center;
    margin-bottom: 10px;
}

.projects-header-detail-text {
    font-size: 20px;
    line-height: 30px;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    font-family: "Google Sans Medium";
}

.publications-heading-text-div {
    text-align: center;
    font-family: "Google Sans Regular";
    margin-top: 70px;
}

.publications-heading-text {
    font-size: 56px;
    font-weight: 600;
}

.repo-cards-div-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem 1rem;
    margin-left: 1px;
    margin-right: 40px;
    margin-top: 10px;
}

.repo-card-div {
    color: rgb(88, 96, 105);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    padding: 1rem;
    cursor: pointer;
    flex: 1 1 27%;
}

.repo-card-div:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

@media (max-width: 1380px) {
    .projects-heading-text {
        font-size: 50px;
        margin-top: 30px;
    }

    .projects-heading-sub-text {
        font-size: 25px;
    }
}

@media (max-width: 768px) {

    .projects-heading-text,
    .publications-heading-text {
        font-size: 30px;
        margin-top: 20px;
    }

    .projects-heading-sub-text {
        font-size: 20px;
    }

    .projects-heading-div {
        flex-direction: column;
    }

    .projects-header-detail-text {
        font-size: 16px;
        margin-right: 0px;
        margin-left: 0px;
        line-height: normal;
        text-align: center;
    }

    .repo-cards-div-main {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 30px;
    }
}



@media (max-width: 568px) {
    .mob-instructor {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 0px 0px;
        padding: 0px 0px;
    }

    .mob-img {
        max-width: 100%;
        ;
    }
}

.repo-stats {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 13px;
    color: rgb(106, 115, 125);
}

.repo-left-stat {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
}

.language-color {
    width: 10px;
    height: 10px;
    background-color: blue;
    margin-right: 0.25rem;
    border-radius: 100%;
}

.repo-left-stat span {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 0.75rem;
}

.repo-name-div {
    display: flex;
    align-items: center;
}

.repo-svg {
    margin-right: 0.5rem;
    min-width: 16px;
}

.repo-name {
    text-align: left;
    color: rgb(36, 41, 46);
    /* font-family: "Google Sans Regular"; */
    margin-bottom: 0.75rem;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.5px;
    overflow: hidden;
    line-height: 1.2;
    margin: 10px;
}

.repo-star-svg {
    margin-right: 0.3rem;
}

.repo-description {
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    /* font-family: "Google Sans Regular"; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 0.8rem;
}

.repo-details {
    display: flex;
    flex-direction: row;
}


@media (max-width: 1200px) {
    .repo-card-div {
        flex: 1 1 40%;
    }
}

@media (max-width: 768px) {
    .repo-card-div {
        flex: 1 1 100%;
    }

    .repo-name {
        font-size: 16px;
    }

    .repo-description {
        font-size: 14px;
    }

    .repo-details {
        flex-direction: column;
    }

    .repo-creation-date {
        font-size: 14px;
    }


}

.category-item {
    cursor: pointer;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    margin-bottom: 0.75rem;
}

.category-item.active {
    background-color: #d6fdfdef;
}

.exams-list.collapsed {
    display: none;
}

.exams-list.expanded {
    display: block;
}