.subscription-table {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
    padding: inherit !important;
}

.header{
    text-align: center !important;
    justify-content: center !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.cardtext{
     /* overflow: hidden;
   text-overflow: ellipsis;
   width: 186px;
   white-space:nowrap; */
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
   text-align: left !important;
}

.price-span {
    font-size: 14px !important;
    margin-left: 40px !important;
}
.card-body-inner {
    padding: 0px !important;
}
.remove-coupon {
    cursor: pointer;
    color: blue;
}
.applied-coupon {
    color: green;
}