@charset "UTF-8";

/*PEN STYLES*/
* {
  box-sizing: border-box;
}

.a,
.a:hover,
.a:focus,
.a:active {
  text-decoration: none;
}

a {
  display: inline-block;
}

object {
  pointer-events: none;
}

.aoverride {
  text-decoration: none;
  color: #232424;
}

.aoverride:hover {
  text-decoration: none;

}

.repo-creation-date {
  margin-left: 0px;
  margin-right: auto;
}

.repo-languages {
  margin-left: auto;
  margin-right: 0px;
}

body {
  background: #f1f1f1;
  /* margin: 2rem; */
}

.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
}

/* .blog-card a {
  color: inherit;
}

.blog-card a:hover {
  color: #5ad67d;
} */

.blog-card:hover .photo {
  transform: scale(1.3) rotate(3deg);
}

.blog-card .meta {
  position: relative;
  z-index: 0;
  height: 200px;
}

.blog-card .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}

.blog-card .details,
.blog-card .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}

.blog-card .details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}

/* .blog-card .details a {
  -webkit-text-decoration: dotted underline;
  text-decoration: dotted underline;
} */

.blog-card .details ul li {
  display: inline-block;
}

.blog-card .details .author:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "";
}

.blog-card .details .date:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "";
}

.blog-card .details .tags ul:before {
  font-family: FontAwesome;
  content: "";
  margin-right: 10px;
}

.blog-card .details .tags li {
  margin-right: 2px;
}

.blog-card .details .tags li:first-child {
  margin-left: -4px;
}

.blog-card .description {
  padding: 1rem;
  background: #fff;
  position: relative;
  z-index: 1;
}

.blog-card .description h1,
.blog-card .description h2 {
  font-family: Poppins, sans-serif;
}

.blog-card .description h1 {
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}

.blog-card .description h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #a2a2a2;
  margin-top: 5px;
}

.blog-card .description .read-more {
  text-align: right;
}

/* .blog-card .description .read-more a {
  color: #5ad67d;
  display: inline-block;
  position: relative;
} */

.blog-card .description .read-more a:after {
  content: "";
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}

.blog-card .description .read-more a:hover:after {
  margin-left: 5px;
  opacity: 1;
}

.blog-card p {
  position: relative;
  margin: 1rem 0 0;
}

.blog-card p:first-of-type {
  margin-top: 1.25rem;

}

.blog-card p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  background: #5ad67d;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
}

.blog-card:hover .details {
  left: 0%;
}

@media (min-width: 640px) {
  .blog-card {
    flex-direction: row;
    height: 200px !important;
    max-width: 1050px;
  }

  .blog-card .meta {
    flex-basis: 40%;
    height: auto;
  }

  .blog-card .description {
    flex-basis: 60%;
  }

  .blog-card .description:before {
    transform: skewX(-3deg);
    content: "";
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .blog-card.alt {
    flex-direction: row-reverse;
  }

  .blog-card.alt .description:before {
    left: inherit;
    right: -10px;
    transform: skew(3deg);
  }

  .blog-card.alt .details {
    padding-left: 25px;
  }
}

.img {
  width: -webkit-fill-available;
}

.bold {
  background: none;
}

@media (max-width: 568px) {
  .mob-enr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10%;
  }

  .text-mob-enr {
    text-align: center;
  }
}

@media (max-width: 429px) {
  .blog-card p:first-of-type {
    text-align: center;
  }

  .blog-card .description .read-more {
    text-align: center;
  }

}



.repo-name-div {
  display: flex;
  align-items: center;
}

.repo-svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.repo-duration {
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
}

.repo-name {
  text-align: left;
  color: rgb(36, 41, 46);
  /* font-family: "Google Sans Regular"; */
  margin-bottom: 0.75rem;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  line-height: 1.2;
  margin: 10px;
}

.repo-star-svg {
  margin-right: 0.3rem;
}

.repo-description {
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  /* font-family: "Google Sans Regular"; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.8rem;
}

.repo-details {
  display: flex;
  flex-direction: row;
}

.repo-cards-div-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem 1rem;
  margin-left: 1px;
  margin-right: 10px;
  margin-top: 10px;
}

.repo-card-div {
  color: rgb(88, 96, 105);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 1rem;
  cursor: pointer;
  flex: 1 1 27%;
}

.repo-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}


@media (max-width: 1200px) {
  .repo-card-div {
    flex: 1 1 40%;
  }
}

@media (max-width: 768px) {
  .repo-card-div {
    flex: 1 1 100%;
  }

  .repo-name {
    font-size: 14px;
  }

  .repo-description {
    font-size: 12px;
  }

  .repo-details {
    flex-direction: column;
  }

  .repo-creation-date {
    font-size: 14px;
  }

  .repo-cards-div-main {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .repo-creation-date {
    margin-left: auto;
    margin-right: auto;
  }

  .repo-languages {
    margin-left: auto;
    margin-right: auto;
  }
}