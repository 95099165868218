.bd-categories {
    display: block;
    /* background-color: #fafafa; */
    /* width: 100%; */
    height: auto;
    background-color: #fafafa;
    position: sticky !important;
    top: 0;
    z-index: 1;
    text-decoration: none;
    --height: 1.25;
    height: 100vh;
    overflow-y: auto;
    background: #fff !important;
}

.btn-block {
    font-weight: 400 !important;
    /* display: flex; */
    padding: 4px 10px;
    border-radius: 5px;
    margin: 1px 0px;
    color: #3e3e3e;
}

.btn-block:hover {
    background-color: #2bc5b4;
    color: #fafafa !important;
}

.activee:hover {
    background-color: #2bc5b4 !important;
    color: #fafafa !important;
}

.activee {
    background-color: #00d1b2 !important;
    color: #fff !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {
    .bd-categoriess {
        display: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}