.profile-head {
    transform: translateY(5rem)
}

.cover {
    background: #fff;
    /* background-image: url(https://images.unsplash.com/photo-1530305408560-82d13781b33a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80); */
    background-size: cover;
    background-repeat: no-repeat
}

body {
    /* background: #654ea3; */
    /* background: linear-gradient(to right, #e96443, #904e95); */
    min-height: 100vh;
    overflow-x: hidden
}

.svgcontainer {
    display: flex;
    justify-content: center;
}

.repo-creation-date {
    margin-left: 0px;
    margin-right: auto;
}

.repo-languages {
    margin-left: auto;
    margin-right: 0px;
}

.image {
    height: 100%;
    width: 100%;
}

@media (max-width: 568px) {
    .screen-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .align-mob {
        align-items: start !important;
    }

    .profile-head {
        display: flex;
        justify-content: center;
        align-items: flex-start !important;
        padding: 0px 0px;
    }

    .img-res-mob {
        width: 100%;
        max-width: 100%;
    }

    .view-mob {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px;
        /* width: 100% !IMPORTANT; */
        padding: 0px 0px;
    }

    .mob-testcenter {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin: 0px 0px !important;
        width: 100%;
    }

    .mob-heading {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

.view-mob {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mt-6 {
    margin-top: 5rem;
}

.repo-name-div {
    display: flex;
    align-items: center;
    text-align: center;
}

.repo-svg {
    margin-right: 0.5rem;
    min-width: 16px;
}

.repo-duration {
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
}

.repo-name {
    text-align: left;
    color: rgb(36, 41, 46);
    /* font-family: "Google Sans Regular"; */
    margin-bottom: 0.75rem;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.5px;
    overflow: hidden;
    line-height: 1.2;
    margin: 10px;
}

.repo-star-svg {
    margin-right: 0.3rem;
}

.repo-description {
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    /* font-family: "Google Sans Regular"; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 0.8rem;
}

.repo-details {
    display: flex;
    flex-direction: row;
}

.repo-cards-div-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem 1rem;
    margin-left: 1px;
    margin-right: 10px;
    margin-top: 10px;
}

.repo-card-div {
    color: rgb(88, 96, 105);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    padding: 1rem;
    cursor: pointer;
    flex: 1 1 27%;
}

.repo-card-div:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}


@media (max-width: 1200px) {
    .repo-card-div {
        flex: 1 1 40%;
    }
}

@media (max-width: 768px) {
    .repo-card-div {
        flex: 1 1 100%;
    }

    .repo-name {
        font-size: 16px;
    }

    .repo-description {
        font-size: 14px;
    }

    .repo-details {
        flex-direction: column;
    }

    .repo-creation-date {
        font-size: 14px;
    }

    .repo-cards-div-main {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        margin-left: 1px;
        margin-right: 10px;
        margin-top: 30px;
    }

    .repo-creation-date {
        margin-left: auto;
        margin-right: auto;
    }

    .repo-languages {
        margin-left: auto;
        margin-right: auto;
    }
}

.a,
.a:hover,
.a:focus,
.a:active {
    text-decoration: none;
}

a {
    display: inline-block;
}

object {
    pointer-events: none;
}

.aoverride {
    text-decoration: none;
    color: #232424;
}

.aoverride:hover {
    text-decoration: none;

}

.repo-creation-date {
    margin-left: 0px;
    margin-right: auto;
}

.repo-languages {
    margin-left: auto;
    margin-right: 0px;
}