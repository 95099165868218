@media (min-width:320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */


}

@media (max-width:480px) {

    /* smartphones, Android phones, landscape iPhone */
    .green {
        width: 100%;
    }
}

@media (min-width:600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    .screen-padding {
        padding-left: 90px;
        padding-right: 90px;
        margin-top: 60px;
        margin-bottom: 20px;
    }

}

@media (min-width:1281px) {

    /* hi-res laptops and desktops */
    .screen-padding {
        padding-left: 90px;
        padding-right: 90px;
        margin-top: 60px;
        margin-bottom: 20px;
    }
}

@media (max-width: 568px) {}