.MailWindow{
    text-align: center;
    justify-content: center;
    /* border: solid; */
    /* border-radius: 20%; */
    margin-bottom: auto;
}

/* .screen-padding {
      padding-left: 90px;
      padding-right: 90px;

  } */

.mailList{
    display: contents !important;
    margin-top: 10px ;
    margin-bottom: 10px;
}

.userdate{
    justify-content: flex-end;
}

.buttons{
    justify-content: flex-end;
    text-align: end;
}

.startChat{
    justify-content: center !important;
    text-align: center !important;


}

.container-chat{
    overflow: auto;
    padding-right: 0% !important;
}
.table-desk{
    max-width: 91%;
}
.table {
    border: 1px solid #c4bdbd;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px){}
@media (max-width: 568px) {
    .mob-alert{
        display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center !important;
}
.mob-width{
        max-width: 56% !important;
}
.mob-width{
    margin: 0px 21%;
}
}