* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans ', sans-serif;
}

/* body {
  padding: 0px !important; */

#ct-container {
  background: fixed !important;

}

.screen-padding {
  margin-top: 5px;
}

body {
  overflow-y: scroll;
  /* padding: 0.1rem !important; */
}

.card-header {
  background-color: rgba(0, 0, 0, .03) !important;
}

.active-link {
  color: #00d1b2 !important;
}