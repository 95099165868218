.custom-button {
    background-color: green;
    color: black;
    border-radius: 0;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.btn-outline-primary:hover {
    color: black;
    background-color: green;
    border-color: green;
}


@media (min-width:320px) and (max-width: 780px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

    .report--header h3,
    .report--header small {
        color: #000 !important;
    }

    .modal-dialog {
        height: 75vh !important;
        display: flex;

    }

    .modal-content {
        margin: auto !important;
        height: fit-content !important;
        min-width: 100%;
    }
}


.custom {
    min-height: 700px;
    min-width: 1200px;
    vertical-align: middle;
}

@media (min-width:480px) {
    /* smartphones, Android phones, landscape iPhone */

}

@media (min-width:600px) {
    span.step {
        background: #cccccc;
        border-radius: 0.8em;
        -moz-border-radius: 0.8em;
        -webkit-border-radius: 0.8em;
        color: none;
        display: inline-block;
        font-weight: bold;
        line-height: 2em;
        margin-right: 5px;
        text-align: center;
        margin: 10px !important;
        border-radius: 33px;
        height: 30px;
        width: 30px;

    }

    .input {
        height: 170px;
        width: 380px !important;
    }

    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */

}

@media (min-width:1125px) {

    /* big landscape tablets, laptops, and desktops */
    .modal-dialog {
        height: 90vh !important;
        display: flex;
        min-width: 950px !important;

    }

    .modal-content {
        margin: auto !important;
        height: fit-content !important;
    }

    .modal {
        margin: auto !important;
        height: 1000px !important;
        width: 1450px !important;
    }

    /* .modalpopup {
        margin: auto !important;
        height: 1000px !important;
        width: 1350px !important;
    } */

    span.step {
        background: #cccccc;
        border-radius: 0.8em;
        -moz-border-radius: 0.8em;
        -webkit-border-radius: 0.8em;
        color: none;
        display: inline-block;
        font-weight: bold;
        line-height: 3em;
        margin-right: 5px;
        text-align: center;
        margin: 6px !important;
        border-radius: 33px;
        height: 50px;
        width: 50px;

    }
}

@media (min-width:1281px) {
    /* hi-res laptops and desktops */

}

/* .question-review {
    padding-top: 12px !important;
    text-align: center !important;
    padding-bottom: 12px !important;
} */
.exam-li li {
    list-style: unset !important;
}

.review-h4 {
    margin-bottom: 0rem !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.pause-button {
    background: cornflowerblue !important;
    color: white !important;
    border-radius: 5px !important;
    padding: 5px !important;
    border: 0px !important;
}

/* .body{
    background-color: rosybrown !important;
} */
.report--header {
    background-image: url('./banner1.png');
    padding-top: 5px;
    padding-bottom: 1px;
    text-align: center;
    margin-top: 0.1rem;

}

.exam-details-image {
    height: 50px;
    width: 60px;
}

.card {
    height: auto !important;
}

.container-fluid {
    background-color: fff;
}

.table,
td,
th,
tr {
    padding: 5px 35px 5px 35px;
    border-collapse: collapse;
    background-color: peachpuff;
    overflow-x: auto;
}

.table-box {
    overflow-x: auto !important;
}

.test-title {
    padding: 25px;

}

.test-subtitle {
    padding: 25px;
}

.exam-details {

    padding: 10px;
}

.exam-instruction {
    border: 1px solid rgba(77, 72, 72, 0.1);
    padding: 15px;
}

.table-title {
    padding: 10px;
    background: greenyellow;
    border-radius: 5px;
    margin-bottom: 15px;
}

.previous-attempts-table {
    padding: 5px;
}

.text-subtitle {
    margin-left: 0cm !important;
}

/*///////////////////////////////Exam Reports////////////////////////*/
.report-images {
    height: 30px;
    width: 30px;
}

.reports-details {
    border: 1px solid rgba(77, 72, 72, 0.1);
    padding-top: 5px;
    padding-bottom: 20px;
    text-align: center;
    margin: 0px !important;

}

/* 
.h3,h4{
    padding-top: 10px;
    padding-bottom: 20px;
    background: none;
  
    
} */
.report-table-header {
    background: rgb(67, 70, 253);
    border-radius: 5px;
    margin-bottom: 15px;
}

.table-report {
    padding-bottom: 20px;
    padding-top: 20px;
}

.popup-padding {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.right-padding {
    padding-right: 10px;
}

.ans-option-padding {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 0px !important;
    width: 5% !important;

    align-items: baseline !important;
}

.dropdown {
    padding-bottom: 20px;
    padding-top: 10px;
    text-align: right;
}

.report--header h3 {
    background: none !important;
    color: rgb(53, 177, 29);
}

.report--header small {
    background: none !important;
    color: #ffffff !important;
}

@media (min-width: 320px) and (max-width: 480px) {
    .report--header {
        padding-top: 10px;
        padding-bottom: 0px;
        text-align: center;
    }

    .report--header h3 {
        background: none !important;
        color: #000000 !important;
    }

    .report--header small {
        color: #000000 !important;
    }

    .sort-text {
        text-align: center !important;
    }

    .question-change {
        text-align: center !important;
        align-items: center !important;
    }

    .questionsList {
        margin-left: 0px !important;
        margin-left: 0px !important;
        padding: inherit;
    }

    span.step {
        background: #cccccc;
        -moz-border-radius: 0.8em;
        display: inline-block;
        font-weight: bold;
        line-height: 3em;
        margin-right: 5px;
        text-align: center;
        margin: 10px !important;
        border-radius: 33px;
        height: 43px;
        width: 43px;
    }

    .previous-btn {
        text-align: -webkit-left !important;
        width: 80% !important;
        text-align: center !important;
    }

    .next-btn {
        text-align: -webkit-right !important;
        width: 80% !important;
        text-align: center !important;
    }
}

/*///////////////////////////////Exam ////////////////////////*/



.exam-title {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center !important;
    margin-bottom: 10px;
}

.question-section {
    padding-top: 3px;
    padding-bottom: 20px;
    padding-left: 3% !important;
    padding-top: 0% !important;
    padding-bottom: 1% !important;
    padding-right: 2% !important;
}

.questionNo {
    padding-top: 10px;
    padding-bottom: 20px;
}

.domain {
    margin-left: 10px;
}

.domain-title {
    padding-top: 10px;
    padding-bottom: 20px;
}

.question {
    padding-top: 10px;
    padding-bottom: 20px;
}

.answer-button {
    margin-top: 100px;
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 20px;
}


.question-change {
    padding-top: 30px;
    padding-bottom: 20px;
}

.question-next {
    justify-content: flex-end;
}

.next {
    padding-right: 5px;
}

.bi {
    background: none !important;
}

.next-label {
    background: none;
}


.input {
    height: 170px;
    width: 100% !important;
}

.navButtons {
    margin: auto !important;
}


/*///////////////////////////////question list ////////////////////////*/

span.step:hover {
    background: cornflowerblue;
}

.questionsList {
    margin-left: 0px;
    margin-right: 10px;

}

.quest-row1 {
    background: none;
}

.attempts {
    background: rgb(123, 195, 240);
    border-radius: 5px;
    padding-left: 5px;
    margin-left: 5px;

}

.attempts-h4 {
    line-height: 2 !important;
    color: white !important;
}

/* /////////////Exam next previous buttons////////////////////// */

/* exam question number pallet */


.answered {
    background: #2ecc71 !important;
    /* Green for only answered */
}

.answeredreview {
    background: #cfcf37 !important;
    /* Yellow for answered and marked for review */
}

.notansweredreview {
    background: #cc602e !important;
    /* Red for not answered and marked for review */
}

.blank {
    background: rgb(240, 234, 234) !important;
    /* Default background color */
}

.question-number {
    background: none;
}



/* ***********  LOADER  *********** */

.loader {
    padding: 11% i !important;
}

.previous-button {
    text-align: left !important;
}

.badgeAnswer {
    background: none;
}

.badge-failure {
    background: red !important;
}

.badgeAnswer-wrong {
    background: red !important;
}

.Incorrect {
    border: 1px solid red !important;
    padding: 10px;
    /* border-radius: 25px; */
}

.Unattempted {

    border: 1px solid #FFBF00 !important;
    /* Amber is a bright, warm color that is midway between yellow and orange. On the hex chart used by web designers and developers, the amber color code is #FFBF00. */
    padding: 10px;
}

.Correct {
    border: 1px solid green !important;
    padding: 10px;
}

@media (max-width: 568px) {
    .headline1 {
        text-align: center !important;
    }
}

.modal-header {
    justify-content: center !important;
}