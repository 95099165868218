.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 5vw;

  list-style-type: none;
}

.card {
  padding: 30px;
  border: solid 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.card-text {
  color: rgba(0, 0, 0, 0.6);
}

.item {
  width: 120px;
  min-height: 120px;
  max-height: auto;
  float: left;
  margin: 3px;
  padding: 3px;
}

.avatar img {
  border: solid 6px transparent;
  border-radius: 50%;
}

.truncate-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  color: black;

}


a:link {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

.item img {
  max-width: 150px !important;
  height: 150px !important;
}

.card {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin: 0px 0px;
  padding: 0px 0px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  /* d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z"); */
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card-title {
  margin-top: 20px;
  color: black;

}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: .8em;
  color: #D7BDCA;
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  font-family: "MockFlowFont";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.ipad-li {
  max-width: 100% !important;
}

.ipad-cards {
  margin: 0px 0px;
}

@media (max-width: 568px) {
  .card__image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
    padding: 0px 0px;
    max-width: 100%;
  }

  .mob-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
    padding: 0px 0px;
  }

  .cont-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
    padding: 0px 0px;
  }

  .card__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
    padding: 0px 0px;
  }

  .ipad-cards {
    margin: 0px 0px;
    margin-top: 2rem;
  }

  .ipad-li {
    max-width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px -17px;
    padding: 0px 0px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .ipad-cards {
    margin: 0px 0px;
  }

  .ipad-li {
    max-width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}