.headers {
    background-color: none !important;
}

.boxCard {
    height: 450px;
}

.chart {
    margin-left: auto !important;
    /* margin: inherit !important; */
}

h4.text {
    margin-left: 180px;
}

.Donut-multiple__subtitles {
    margin: auto;
    position: relative !important;
    left: 67%;
    transform: translateX(-41%);
}

.card-title-top {
    margin-top: 50px;
    color: black;
}