.banner-img {
  padding-bottom: 40px !important;
}

.popular-cat-link {
  color: black !important;
  margin-bottom: 25px !important;
}

.popular-cat-link:hover {
  color: black !important;
  margin-bottom: 25px !important;
}

.home-h4 {
  color: black;
  font-size: 29px;
  padding-top: 24px;
}

.home-img {
  margin-top: -230px;
}

.popular-cat-link-p:hover {
  background: #00A600 !important;
  border-radius: 10px !important;
  text-decoration: none !important;
}

.home__hero-section {
  color: #fff;
  padding: 0px 0;
  margin-top: 10px;
}

.home__hero-row {
  align-items: center !important;
}

.row {
  display: flex;
  /* margin-right: -12px;
  margin-bottom: -15px;
  margin-left: -15px; */
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  /* padding-bottom: 60px; */
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 32px !important;
}

.heading {
  margin-bottom: 16px;
  margin-top: 24px;
  font-size: 20px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;

}



.heading1 {
  margin-bottom: 10px;
  font-size: 20px !important;
  line-height: 1.1;
  font-weight: 600;
  color: #1c2237;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {

  position: relative;
  left: 30px;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.slider {
  text-align: left;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }

  .resp {
    height: 380px;
  }
}

@media screen and (max-width: 768px) {

  /* .home__hero-text-wrapper {
    padding-bottom: 65px;
  } */
  .resp {
    height: 380px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }

  .goalstab {
    margin-top: 30px !important;
  }
}

@media all and (min-width: 320px) {
  .img-col {
    width: 30%;
  }

  .instructor-img {
    padding-top: 135px;
    max-width: 100%;
  }

  .heading {
    text-align: center;
  }


}

@media all and (min-width: 480px) {
  .deskContenthero {
    display: block;
  }

  .phoneContenthero {
    display: none;
  }

  .heading {
    text-align: left;
  }
}

@media all and (min-width:374px) and (max-width: 579px) {
  .deskContenthero {
    display: none;
  }

  .phoneContenthero {
    display: block;
  }

  .instructor-img {
    padding-top: 60px
  }

}


.mob-resp {
  text-align: center;
  justify-content: center !important;
}



@media (min-width:1020px) {

  /* big landscape tablets, laptops, and desktops */
  .home__hero-row {
    align-items: center !important;
    margin-left: 60px !important;

  }

  .instructor-img {
    padding-top: 8px;
  }

}

@media (min-width:1279px) {

  /* hi-res laptops and desktops */
  .home__hero-row {
    align-items: center !important;
    margin-left: 60px !important;
  }

  .instructor-img {
    padding-top: 38px;
  }

  .resp {
    height: 100vh !important;
  }

}


.bestTop {
  font-size: 44px;
  margin-bottom: 20px;
  font-weight: bold;
  margin-left: 0px;
}

@media (max-width: 568px) {
  .text-mob {
    text-align: center;
  }

  .conent-mob {
    height: 4rem;
    width: 100%;
    padding: 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5% !important;
    margin-bottom: 1rem !important;
  }

  .bottom-mob {
    padding-bottom: 10px !important;
  }

  .textres--mob {
    font-size: 18px;
  }

  .heading1 {
    text-align: center;
  }

  .home__hero-subtitle {
    text-align: center;

  }

  .colr {
    background-color: #abb1ab;
    color: white;
    border-radius: 6px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin: 0px 15px;
    height: 46px;
    padding: 0px 0px;
  }

  hr {
    padding: 0px 3rem !important;
    width: 70%;
    background-color: black;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-top: 0.5px solid rgba(0, 0, 0, .1);
  }

  /* .home-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;   
    padding: 8px !important;
    text-align: center !important;
    background-color: #ffffff !important;
    margin-bottom: 10px !important
  } */

  .card {
    min-width: 100% !important;
    /* margin: 0px 5% !important; */
  }

  /* .card-header:first-child {
    height: 25px !important;
  } */

  /* .card-header:first-child {
    width: 100% !important;
    height: 2rem;
    padding: 10px 5px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #ffffff !important;
    border-bottom: 0px !important;
  } */
}

.bg-color {
  width: 100%;
  background-color: #959393;
  color: white;
  border-radius: 5px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 88% !important;
  margin: 0px 5px;
  height: 4rem;
}

/* .card {
       min-width: 104% !important;
} */
/* .card-header:first-child {
  width: 100% !important;
  height: 3rem;
  padding: 10px 5px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #ffffff !important;
  border-bottom: 0px !important;
} */

.desk {
  margin: 28px 0px !important;
}

.desk-v {
  padding-left: 0px !important;
}

/* .stud{
  height: 83vh;
} */