* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  background-color: #eee;
  font-family: sans-serif;
}

h1 {
  font-size: 200%;
}

/* section {
  padding: 10px 20px;
  margin-bottom: 20px;
  margin-left: 10px;
} */

.text-transition {
  font-size: 15px !important;

}

.inline div {
  color: black !important;
  font-size: 16px !important;

}

.big {
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: bold;
}

@media (min-width:1025px) {

  /* big landscape tablets, laptops, and desktops */
  .inline {
    align-items: center !important;
    /* margin-left: 60px !important; */
  }
}

@media (min-width:1281px) {

  /* hi-res laptops and desktops */
  .inline {
    align-items: center !important;
    /* margin-left: 60px !important; */
  }
}