.hover-2 {
    background: linear-gradient(#1095c1 0 0) no-repeat calc(200% - var(--p, 0%)) 100% / 200% var(--p, 0.08em);
    transition: 0.3s var(--t, 0s),
        background-position 0.3s calc(0.3s - var(--t, 0s));
}

.hover-2:hover {
    --p: 100%;
    --t: 0.3s;
    color: #fff;
}

/** sidebar width*/
.navbar-vertical.navbar-expand-lg {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
}


.navbar-vertical .navbar-nav .nav-link {
    display: flex;
    align-items: center;

}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-link {
    padding-left: 2.25rem;
}

.navHeading {
    padding-left: 0.35rem;
}

.navPagesSub .nav-item .nav-link {
    padding-left: 3.25rem !important;
}


.wrapper {
    position: absolute;


    background-color: #290e0ead;
    border-radius: 5px;
}

#sidebarCollapse {
    position: absolute;
    top: .5rem;
    right: 1.3rem;
}

#sidebar {
    height: inherit;
    margin: 2rem;
    text-align: left;
}

#sidebar ul li a {
    margin-left: .5rem;
    color: #ffffff;
}

#sidebar .list-item {
    margin: 2rem 0;
    font-size: 15px;
    font-weight: 600;
}

#sidebar li a {
    text-decoration: none;
    padding-left: .5rem;
}

.icon-color {
    color: #738a9b;
}

.btn-custom {
    background-color: #ec7676;
    border-radius: 50%;
    color: #5388bd;
    font-weight: 600;
}

.sidebar-header {
    margin-top: 4rem;
    text-align: center;
    color: #e9e9ea;
}

.usr-image {
    border: 2px solid white;
}

.collapse {
    display: none;
}

#sidebar.active .sidebar-header h3 {
    display: none;
}

#sidebar ul li a {
    text-align: left;
}

#sidebar.active ul li a {
    display: none;
}

#sidebar.active ul li i {
    display: block;
}

#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

.hidden {
    display: none;
}

@media (max-width: 768px) {
    #sidebar.active {
        width: 50px;
        text-align: center;
        margin: 0;
    }

    #sidebar {
        margin: 1rem;
    }

    #sidebarCollapse {
        right: .5rem;
    }

    #sidebar.active .sidebar-header h3 {
        display: none;
    }

    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }
}



.bd-categories {
    display: block;
    /* background-color: #fafafa; */
    /* width: 100%; */
    height: auto;
    background-color: #fafafa;
    position: sticky !important;
    top: 0;
    z-index: 1;
    text-decoration: none;
    --height: 1.25;
    height: 100vh;
    overflow-y: auto;
    background: #fff !important;
}

.btn-block {
    font-weight: 400 !important;
    /* display: flex; */
    padding: 4px 10px;
    border-radius: 5px;
    margin: 1px 0px;
    color: #2c2c2c;
    text-align: left;
}

.btn-block:hover {
    background-color: #1095c1;
    color: #fafafa !important;
}

.activee:hover {
    background-color: #b9cac8 !important;
    color: #fafafa !important;
}

.activee {
    background-color: #b9cac8 !important;
    color: #fff !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .bd-categories {
        height: auto;
        z-index: 2;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}