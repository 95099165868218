@media (min-width:801px) {

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .form-radio {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .h2 {
        margin-left: 30px !important;
    }

}

.summernote .note-editable {
    text-align: left !important;
}

.summernote .note-editor {
    text-align: left !important;
}

/* For summernote override unordered and order list */
.note-editable ul {
    list-style: disc !important;
    list-style-position: inside !important;
}

.note-editable ol {
    list-style: decimal !important;
    list-style-position: inside !important;
}

.note-editable {
    padding-left: 5px !important;
}

.qbody {
    height: fit-content !important;
}

/* .form-check-input{
    margin-left: 10px;
    margin-right: 10px;
} 

.note-btn-group button {
    cursor: pointer;
    background: white !important;
    background-color: white !important;
    color: black !important;
} */

.note-editor .note-toolbar,
.note-popover .popover-content {
    border-bottom: 1px solid gray !important;
}

.head {
    margin-top: 15px;
    margin-bottom: 10px;
}

.ExamData {
    margin-top: 10px;
}

.custom-select {
    width: 50%;
}

.negative-mark {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.twitter-embed-container {
    display: flex;
    justify-content: center;
    /* Center the embedded tweet */
    transform: scale(0.6);
    /* Scale down to 80% */
    transform-origin: top center;
    /* Center scaling from the top */
    margin: 20px 0;
    /* Add some vertical space if needed */
}

.twitter-embed-container blockquote.twitter-tweet {
    margin: 0 auto;
    /* Center the tweet itself if needed */
}



.form-group {
    margin-top: 5px;
    margin-bottom: 5px;
}

.categories {
    margin-bottom: 5px;
    margin-top: 5px;
}

.form-check {
    margin-left: 10px;
    margin-right: 10px;
}

.form-radio {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.h2 {
    margin-left: 30px !important;
}

.border {
    padding: 8px;
}

.hidden {
    display: none;
}

.show {
    display: block;
}

.tabshow {
    display: block;
    position: relative;
}

.tabhidden {
    display: none;
}

.prevnexthidden {
    display: none !important;
}

.prevnextshow {
    display: block !important;
}

.navigationhidden {
    display: none !important;
}

.navigationshow {
    display: block !important;
}

.questions-pallet {
    margin: 5px 0px !important;
}

.question-number {
    width: 30px;
    height: 20px;
    background: rgb(231, 220, 220);
    text-align: center !important;
    cursor: pointer;
    color: rgb(19, 18, 18);
}

.exam-thumbnail {
    padding-bottom: 30px;
}

.btn-float-right {
    float: right;
}

/* Prieview Exam */
.answer-choise {
    margin-top: 1px;
    margin-bottom: 1px;
    margin-left: 10px;
    margin-right: 10px;
}

.answers {
    margin-left: 10%;
    margin-right: 10%;
}

.modal-box {
    padding: 2% !important;
}

.fieldset-custom {
    margin-top: 5px !important;
}

.valid_question {
    background: green !important;
}

.table-background {
    background: #FFFFFF !important;
}

.rounded-circle {
    border-radius: 50% !important;
    border: 2px solid black;
    color: black;
    /* margin: 6px; */
    height: 30px;
    width: 30px;
    background-color: #bbb;
    display: inline-block;
    margin: 5px 0PX;
    DISPLAY: flex;
    JUSTIFY-CONTENT: center;
    ALIGN-ITEMS: center;
}

.MARGINbOX {
    MARGIN: 0PX 7PX;
}

.required:after {
    content: " *";
    color: red
}

.invalid_question {
    background: orange;
}

.table,
td,
th,
tr {
    background: #FFFFFF !important;
}

.ck-editor__editable {
    height: 250px !important;
}

.required-validation {
    font-size: 10px !important;
    padding-left: 10px !important;
    color: red;
}

.note-dimension-picker-mousecatcher {
    background: bottom !important;
}

.m2show {
    display: flex !important;
}

.m2hidden {
    display: none !important;
}

.m3show {
    display: flex !important;
}

.m3hidden {
    display: none !important;
}

.m4show {
    display: flex !important;
}

.m4hidden {
    display: none !important;
}

.m5show {
    display: flex !important;
}

.m5hidden {
    display: none !important;
}

.modal-header {
    background: lightblue !important;
}

.modal-footer {
    background: lightblue !important;
}

.text-wk-center {
    text-align: -webkit-center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .screen-padding-exs {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .screen-padding-exs {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .screen-padding-exs {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .screen-padding-exs {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .screen-padding-exs {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }
}

.report--header {
    margin-bottom: 16px;
}

.summernote {
    margin-bottom: 16px;
    /* Add space below the editor */
    overflow: auto;
    /* Ensure content doesn't overflow */
}

.d-flex {
    position: sticky;
    /* Keep buttons visible if needed */
    bottom: 16px;
    z-index: 10;
    /* Ensure buttons are above the editor content */
    background-color: white;
    /* Background to avoid overlap */
    padding: 8px 0;
}


.custom-editor-wrapper .note-editable ul,
.custom-editor-wrapper .note-editable ol {
    list-style: initial !important;
    /* Force restore bullets/numbers */
    margin-left: 1.5em !important;
    /* Force proper indentation */
    padding-left: 1.5em !important;
}

.custom-editor-wrapper .note-editable li {
    margin-bottom: 10px !important;
    /* Ensure spacing between list items */
}

/* Override the popover button group */
.custom-editor-wrapper .note-popover .popover-content>.note-btn-group,
.custom-editor-wrapper .note-editor .note-toolbar>.note-btn-group {
    margin-left: 10px !important;
    /* Change left margin */
    margin-right: 25px !important;
    /* Change right margin */
}