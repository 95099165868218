.search-input {
  width: 100% !important;
  padding-left: 20px !important;
}

.form-control {
  width: 100% !important;
}

.btn-sidebar {
  width: 99% !important;
  /* padding-right: 5px !important; */
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* this adds the "card" effect */
  padding: 6px;
  text-align: center;
  /* background-color: #f1f1f1; */
}

.card__description {
  color: black;
}

/* Responsive columns - one column layout (vertical) on small screens */
@media screen and (max-width: 600px) {
  .column {
    width: 90%;
    display: block;
    margin-bottom: 20px;
    margin-left: 18px !important;
    ;
  }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .cat-btn {
    width: 21%;
  }
}

@media (max-width: 568px) {
  .cat-btn {
    width: 36%;
    padding: 0px 0px;
    margin: 10px 10px;
  }

}