/* .row {
  flex-wrap: inherit;
} */
.footer-li li {
  list-style: unset !important;
}

.social-link-row {
  padding-bottom: 30px !important;
  flex-wrap: initial !important;
}

.footer-custom {
  color: #000000 !important;
  background-color: #fff !important;
  border: none;
}

.modal-footer {
  text-align: center !important;
}

.blue {
  color: #000000 !important;
  background-color: #fff !important;
  border: none;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  cursor: pointer;
}

.link-row {
  padding-top: 30px !important;
  flex-wrap: inherit !important;
}

.footer-container {
  background-color: #fff;
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: black;
}

.footer-subscription>p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 18px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 4px 24px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 10px;
}

.footer-link-items>h2 {
  color: black;
}

.footer-link-items a {
  color: black;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: black;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: black;
  font-size: 18px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  margin-left: 4px;
}

.social-logo {
  color: black;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
}

.website-rights {
  color: black;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }



  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .app-download {
    margin-left: 100px;
  }


  .store-image {
    width: 250px !important;
  }
}

@media (min-width:1025px) {

  /* big landscape tablets, laptops, and desktops */
  .footer-custom {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
}

@media (min-width:1281px) {

  /* hi-res laptops and desktops */
  .footer-custom {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
}