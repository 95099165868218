* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.resume-exam {
  cursor: pointer !important;
  color: blue;
}

.float-right {
  float: right;
}

.centered-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.sidebar {
  display: block;
  background-color: #fafafa;
  width: 100%;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-top: 1px;
}

/* .sidebar {
    margin: 0; 
    width: fit-content;
    background-color: white;
    position: fixed;
    height: 100%;
    overflow: auto;
    border-right: 1px solid;
  } */
.mob-sidebar1 {
  display: inline !important;
}

.name {
  width: max-content !important;
}

a {
  text-decoration: none;
}

.details {
  margin-right: auto !important;
}

/* .sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
  } */
.sidebar a {
  display: block;
  /* color: #363636; */
  /* padding: 4px 6px; */
  text-decoration: none;
  color: #0f1113d3;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  /* margin:0px 18px; */

}

/* .sidebar a.active {
    background-color:white;
    color:black;
  } */

/* .sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
  } */
.sidebar a:hover:not(.active) {
  /* background-color:whitesmoke; */
  /* color:#00d1b2; */
  /* color: #09b0ce; */
  color: black;
  text-decoration: none;
  /* padding: 8px 14px; */
  box-sizing: inherit;
  /* background-color: #fff;  */
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1500px;
}

@media screen and (max-width: 700px) {

  /* .sidebar {
      display: none;
    } */
  .sidebar {
    /* margin: 0;
    width: -moz-fit-content;
    width: fit-content;
    background-color: white;
    position: fixed !important; */
    height: 100%;
    overflow: auto;
    border-right: 1px solid;
  }
}

@media screen and (max-width: 700px) {

  /* .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }  */
  /* .sidebar a {float: left;} */
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  /* .sidebar a {
      text-align: center;
    } */
}

.card {
  height: 100px;
  width: 200px;
}

.paddinglet {
  padding-left: 0px !important;

}

.element {
  width: 460px !important;

}

.cardMobileWidth {
  width: 446px;
}

@media (max-width: 960px) and (min-width: 501px) {
  .cardMobileWidth {
    width: 260px;
  }

}

@media (max-width: 500px) {
  /* .press li { width: 100%; }  */
  /* On small screens, show one logo per row */

  .cardMobileWidth {
    width: 349px;
  }

  /* .side{
      display: none!important
    } */
  .card-list-ui {
    text-align: -webkit-right;
  }

  .edu-title {
    text-align: right;
  }

  .content-exam {
    text-align: center;
    margin-left: 56px;
  }
}

.report-images {
  height: 30px;
  width: 30px;
}

.reports-details {
  border: 1px solid green;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  margin-bottom: 1px;

}

/* .h3,h4{
    padding-top: 10px;
    padding-bottom: 20px;
    background: none;
   
    
} */
.report-table-header {
  background: greenyellow;
  border-radius: 5px;
  margin-bottom: 15px;
}

.table-report {
  padding-bottom: 20px;
  padding-top: 20px;
}

.dropdown {
  padding-bottom: 20px;
  padding-top: 10px;
  padding-right: 0px;
  text-align: right;
}

.report--header h3 {
  background: none !important;
  color: #ffffff;
}

.report--header small {
  background: none !important;
  color: #ffffff !important;
}

@media (min-width: 500px) and (max-width: 950px) {
  .dropdown {
    padding-bottom: 20px;
    padding-top: 10px;
    padding-right: 12px;
    text-align: right;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .report--header {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
  }

  .report--header h3 {
    background: none !important;
    color: #000000 !important;
  }

  .report--header small {
    color: #000000 !important;
  }

  .sort-text {
    text-align: center !important;
  }

  .question-change {
    text-align: center !important;
  }

  .questionsList {
    margin-left: 0px !important;
    margin-left: 0px !important;
  }

  container1 span.step {
    background: #cccccc;
    -moz-border-radius: 0.8em;
    display: inline-block;
    font-weight: bold;
    line-height: 3em;
    margin-right: 5px;
    text-align: center;
    margin: 10px !important;
    border-radius: 33px;
    height: 43px;
    width: 43px;
  }

  .previous-btn {
    text-align: -webkit-left !important;
  }

  .next-btn {
    text-align: -webkit-right !important;
  }
}

.content-exam {
  margin: auto;
}

/* body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
} */

html {
  box-sizing: border-box;
}

container1 *,
*:before,
*:after {
  box-sizing: inherit;
}

.column {
  float: left;
  width: 23.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 6px;
}

.about-section {
  padding: 50px;
  text-align: center;
  background-color: #474e5d;
  color: white;
}

/* .container {
  padding: 0 16px;
} */

/* .container::after, .row::after {
  content: "";
  clear: both;
  display: table;
} */

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}


/* .instructors{
   width: 16%; 
   float: left; 
   padding: 2%;
} */

/* .block{
   width: 16%; 
   float: left; 
   padding: 2%;
} */




body {
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fef8f8;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.exam-cards {
  display: inline-grid !important;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  /* margin: 4rem 5vw; */
  padding: 0;
  list-style-type: none;
  /* max-width: unset !important; */
}

.card {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  /* d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z"); */
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #6A515E;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: .8em;
  color: #D7BDCA;
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  font-family: "MockFlowFont";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.desk-p-img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  /* margin: 1px 28%; */
}

.stud-landing {
  width: 100%;
  height: 18rem;
}

/* .studland-content{
  height: 17rem;
} */

@media (max-width: 568px) {

  /* .exam-mob{
      display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0px 0px;
} */
  /* .mob-t{
  text-align: center;
} */
  .desk-p-img {
    margin: 0px 0%;
  }

  /* .mob-sidebar{
  float: left; */
  /* height: 21rem; */
  /* } */
  .mob-pro {

    width: 100% !important;
    /* float: right !important; */
    /* margin-left: 36%; */
  }

  /* .sidebar{
   display: none !important;
} */
  .mob-sidebar1 {
    display: contents !important;
    position: fixed !important;
  }

  /* .sidebar {
      display: none;
    } */

  .card-mobview {
    width: 100% !important;
    float: right;
    margin-left: 0px !important;
    min-width: 41% !important;
    margin: 0px 4% !important;
  }

  .exam-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0px 0%;
  }

  .mob-view-tab {
    width: 48%;
    padding: 5px 8px;
    margin: 4px 2px;
    text-align: center;
    background-color: #e7e0e0;
  }

  .ex-mob {
    text-align: center;
  }

  /* .mob-t{
  padding: 0px 0% !important;
} */
  .mobresponimg {
    padding: 0px 0px;
    width: 76%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mob-hedt {
    text-align: center !important;
  }

  .stud-landing {
    width: 100%;
    height: 13rem;
  }

  /* .mobbtn{
  margin-bottom: 0rem !important;
} */
  .studland-content {
    height: 19rem;
  }

  .bg-col {
    background-color: #dbd3d3;
    margin: 5px 0px;
  }
}

.mob-hedt {
  text-align: center;
}

@media only screen and (min-device-width : 360px) and (max-device-width : 640px) {
  .mobresponimg {
    padding: 0px 0px;
    width: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* .mob-view-tab{
  padding: 5px 8px;
    margin: 0px 2px;
    background-color: #e7e0e0;
} */
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .desk-p-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    /* margin: 2px 24%; */
  }
}

/* .mob-t{
  padding: 0px 38%;
} */
/* .desk-view-stud{
max-width: 13.666667% !important;
} */
/* .desk-view-card{
      margin: 4rem 0vw !important;
}
.desk-view-card{
  max-width: 100% !important;
  
} */

.container1 {
  margin-top: 2px;
}