.option-label {
    display: block;
}

.option-letter {
    font-weight: normal;
    margin-right: 5px;
}

.option-text {
    flex-grow: 1;
}

.option-wrapper {
    display: flex;
    align-items: center;
}

.option-wrapper input[type="radio"] {
    margin-right: 7px;
}

.option-box {
    display: flex;
    align-items: center;
    background-color: #eaf2f4;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.option-box-unselect {
    display: flex;
    align-items: center;
    background-color: #a3cfda;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.explanationStyle {
    border: 1px solid #ccc;
    padding: 10px;
}

.explanationTextStyle {
    color: #666666;

}

.timer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timer {
    margin-right: 5px;
}

.custom-button {
    background-color: green;
    color: black;
    border-radius: 0;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.btn-outline-primary:hover {
    color: black;
    background-color: green;
    border-color: green;
}