.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 350px;
    z-index: 1000;
}

.popup-content h4 {
    margin-bottom: 15px;
}

.popup-input {
    display: block;
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error-text {
    color: red;
    margin-bottom: 10px;
}

.popup-actions {
    display: flex;
    justify-content: space-between;
}

.cancel-btn,
.submit-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-btn {
    background-color: #ccc;
}

.submit-btn {
    background-color: #007bff;
    color: white;
}

.open-popup-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup-container::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
}