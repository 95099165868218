body {
    /* margin-top:20px; */
    color: #1a202c;
    text-align: left;
    background-color: #e2e8f0;
}

.ccode {
    padding-top: 9px !important;
}

.profileimage {
    margin-top: 0rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    max-width: 85% !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.hr {
    margin-top: 5px;
    margin-bottom: 15px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.hrprofile {
    margin-top: 2px;
    margin-bottom: 2px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

/* .main-body {
    padding: 15px;
} */

.avatar img {
    border: solid 6px transparent;
    border-radius: 50%;
}

.card {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
    height: 396px !important;
}

.mob-sidebar2 {
    display: none !important;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    height: fit-content !important;
}

.card-body {
    flex: 1 1 auto !important;
    min-height: 1px;
    padding: 1rem !important;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col,
.gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}

.h-100 {
    height: 100% !important;
}

.shadow-none {
    box-shadow: none !important;
}

.progress {
    height: 5px;
}

.progress-bar {
    width: 80%;
}

.edit-button {
    margin-right: 10% !important;
}

.instructor-card {
    width: 30%;
}

/* .password-window{

} */
.card-image {
    justify-content: center !important;
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;

}

@media (max-width: 568px) {
    .mob-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0px 0px;
    }

    .mob-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card {
        /* min-width: 100% !important; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px !important;
    }

    .mob-align {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        margin: 10% 0% !important;
        /* float: right; */
    }

    .mob-side {
        min-width: 100% !important;
        /* margin-left: 11rem !important; */
    }

    .sidebar {
        display: inline !important;
    }

    .mob-sidebar2 {
        display: contents !important;
        position: fixed !important;
    }
}

.desk-cent {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: none;
    background-color: #f0f8ff0d !important;
}

.stud-prof {
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* margin: 0px 60%; */
    width: 100%;
}

.profileinfo {
    margin: 10px 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 82% !important;
}

.info {
    width: 87%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .btn-res {
        width: 27%;
    }

    .stud-prof {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin: 0px 34%;
        width: 52%;
    }

    .mob-side {
        width: 100%;
        margin: 0px 39%;
        max-width: 108%;
    }

    .mob-align {
        max-width: 57% !important;
        padding: 0px 0px !important;
        margin: 1px 13% !important;
    }

    .stud-prof {
        margin: 0px;
    }

    /* h6 {
        width: 3rem;
    } */
}

.mob-align {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 37%;
}